//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_6756,_8936,_1980,_9444,_8112,_5984,_7300,_1087;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_6756,_8936,_1980,_9444,_8112,_5984,_7300,_1087");
        }
      }catch (ex) {
        console.error(ex);
      }